.musiccard-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 20px;
  text-decoration: none;
}

.musiccard-title {
  font-size: 16px;
}

.musiccard-type-year {
  font-size: 15px;
  color: #6a6a6a;
}

.musiccard-wrapper img {
  width: 100%;
  margin-bottom: 10px;
}

.musiccard-wrapper img:hover {
  opacity: 65%;
  transition: all 0.3s ease 0s;
}
