.videoitem {
  background: black;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  color: var(--pink);
  transition: color 1s;
  
  
  transition: font-size 1s;
}

.b-image {
  background-size: cover;
  background-position: center;
  opacity: 0.6;
  filter: grayscale(1);
  transition: 1s filter;
  transition: 1s opacity;
  width: 100vw;
  min-height: 20vh;
  height: 50vh;
  
}

.b-image:hover {
  opacity: 1;
  filter: grayscale(0);
  transition: 1s filter;
  transition: 1s opacity;
  
}

.v-disc {
  position: absolute;
  z-index: 5;
  margin-right: 10%;
  text-align: right;
  font-size: 1.3em;
  letter-spacing: 1vw;
}


.v-disc p:nth-child(2) {
  margin-top: 20px;
  font-size: 15px;
}

.videoitem:hover {
  color: #e2c7c7;
  /* font-size: 22px; */
}

@media (max-width: 768px) {
  .v-disc {
    text-align: center;
    margin-right: 0%;
  }
  .v-disc p:nth-child(2) {
    margin-top: auto;
  }
  .videoitem {
    align-items: center;
  }
}
