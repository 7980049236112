.videos-wrapper {
    background: var(--pink);
    font-family: 'Abel', sans-serif;
    text-transform: uppercase;
}

.videos-wrapper h2 {
    text-align: center;
    padding: 50px;
    font-size: 30px;
    color: black;
}

.video-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}