.music {
  max-width: 1000px;
  text-align: center;
  margin: 0px auto;
  font-family: "Abel", sans-serif;
}

.music-wrapper {
  padding: 0 max(10px, 4vmin);
  background: black;
  padding-bottom: 50px;
  color: var(--pink);
}

.music-wrapper h2 {
  padding: 50px;
  text-transform: uppercase;
  font-size: 30px;
}

.music-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px;
}

.btn-show {
  font-family: "Abel", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  background: var(--pink);
  padding: 20px;
  width: 200px;
  text-align: center;
  color: black;
  margin-top: 30px;
  
}

.btn-show:hover {
  background: rgba(0, 0, 0, .5);
  outline: 1px solid var(--pink);
  outline-offset: -1px;
  color: var(--pink);
  cursor: pointer;
}
