* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    border: 0;
    scroll-behavior: smooth;
} *

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-in {
	-webkit-animation: fade-in 1.2s ease-in both;
	        animation: fade-in 1.2s ease-in both;
}

 @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }