@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');



body {
    background-color: black;
    --pink: #C29898;

}

a {
    color: unset;
    text-decoration: none;
}
