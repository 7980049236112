.about-wrapper {
  display: grid;
  justify-content: center;
 
  background: black;
  color: var(--pink);
  font-family: "Abel", sans-serif;
  margin-bottom: 3em;
}

.about-wrapper h2 {
  text-transform: uppercase;
  padding: 50px;
  font-size: 30px;
  text-align: center;
}

.about-flexbox {
  display: flex;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(800px, 1fr)); */
  gap: 2em;
  
  max-width: 1200px;
}

.btn-socials {
  display: grid;
  justify-items: center;
  justify-content: center;
}

.about-btn-email {
  font-family: "Abel", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  background: var(--pink);
  padding: 20px;
  width: 200px;
  text-align: center;
  color: black;
  margin-top: 1em;
  
}

.about-btn-email:hover {
  background: rgba(0, 0, 0, .5);
  outline: 1px solid var(--pink);
  outline-offset: -1px;
  color: var(--pink);
  cursor: pointer;
  
}


.about-photo {
  
  background-image: url("./../../../public/images/agphoto.jpg");
  background-size: cover;
  background-position: center;
 
  height: 100%;
  width: 200%;
 
  
  
}



.about-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--pink);
 /* max-width: 80%; */
  margin: 0 20px;
  font-family: "Open Sans", sans-serif;
  
  
  font-size: 1em;
  line-height: 1.6;
  
}
.about-text p {
  margin-bottom: 1em;
}

.socials-about {
  margin: 2em 0;
  transition: all 0.3s ease 0s;
}

.socials-about a {
  transition: all 0.3s ease 0s;
  margin: 0 1vw;
  vertical-align: middle;
}

@media ( max-width: 1024px) {
  .about-flexbox {
    flex-direction: column;
  }
  .about-photo {
    height: 50vh;
    width: auto;
  }
}