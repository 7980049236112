.main-wrapper {
    position: relative;
    background-image: url("/public/images/wrapperbackground.jpg"); 
    background-size: cover;
    background-position: 75%;
    /* background-attachment: fixed; */
    position: relative;
    height: 100vh;
    width: 100vw;
    color: var(--pink);
    }

.title-wrapper {
    display: flex;    
    padding-top: 50vh;
    padding-left: 25vw;
    font-size: calc(1em + 0.5vw);
    text-transform: uppercase;
    font-family: 'ABeeZee', sans-serif;
    font-weight: 200;
    letter-spacing: 2vw;
    z-index: 50;  
}
.title-blend {
    mix-blend-mode: difference;
}

@media (max-width: 600px) { 
    .title-wrapper {
        position: fixed;
        
        padding: 17px 20px;
        letter-spacing: 9px;
        font-size: 15px;
    }
}

.fade-in {
	-webkit-animation: fade-in 2s both;
	        animation: fade-in 2s both;
}

.fade-out {
	-webkit-animation: fade-out 2s both;
	        animation: fade-out 2s both;
}
