.navbar {
    font-family: 'Abel', sans-serif;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr auto 1fr;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 20px;  
}

.socials {
    padding-left: 20px;
    transition: all 0.3s ease 0s; 
}

.socials a {
    transition: all 0.3s ease 0s;
    margin: 0 1vw;
    vertical-align: middle;
}

.link {
    display: inline-block;
    margin: 10px 1vw;
    padding: 10px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
}

.link:hover {
    color: #e2c7c7
}

.socials a:hover {
    color: #e2c7c7
} 

@media ( max-width: 800px) {
    .socials a {
      display: none;
    }
}

@media ( max-width: 600px) {
    .navbar {
      display: none;
    } 
}